

//.carousel{
//  z-index: -1;
//}


.mainContainer {
    margin: 30px;
}

.page-footer {

    background-color:#343434;
    color: #ffffff;
    padding: 0 15px 0 15px;
    margin-top: 20px;
    padding-top: 20px;

}
.page-footer a {
    color: #ffffff;
}
.menuFooter a {
    font-size: 12px;
}
.menuFooter li {
    line-height: 16px;

}
.menuFooter li::before {
    content: "  \2022 "; /* Insertamos el marcador */
    padding-right: 6px; /* Establecemos el espacio entre las viñetas y el list item */

}
.bgTitleSeccion {
    background-color: #474747;
}
.mainMenuEc {
    font-size: 14px;
}

.mainMenuEc:hover, .mainMenuEc li.active {
    color: #ffffff;
}

.checkTyC {
    font-size: 10px;
}
.checkTyC span {
    color: #b18431;
}
#navbarsHeader li a.nav-link {
    padding-top:0 !important;
    padding-bottom:0 !important;

}


@media (min-width: 768px) {

    .mainMenuEc li {
       /* display: inline-block;
        width: 20%;*/
    }

    .input-search {
        border-radius:80px;
        width: 30%;

    }


.mainContainer {
    margin: 30px;
  }

  .page-footer {
    background-color: #343434;
    color: #ffffff;
    padding: 0 15px 0 15px;
    margin-top: 20px;
    padding-top: 20px;
  }

  .page-footer a {
    color: #ffffff;
  }

  .menuFooter a {
    font-size: 12px;
  }

  .menuFooter li {
    line-height: 16px;
  }

  .menuFooter li::before {
    content: "  \2022";
    /* Insertamos el marcador */
    padding-right: 6px;
    /* Establecemos el espacio entre las viñetas y el list item */
  }

  .bgTitleSeccion {
    background-color: #474747;
  }

  .mainMenuEc {
    font-size: 14px;
  }

  .mainMenuEc:hover,
  .mainMenuEc li.active {
    color: #ffffff;
  }

  .checkTyC {
    font-size: 10px;
  }

  .checkTyC span {
    color: #b18431;
  }

  #navbarsHeader li a.nav-link {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .btn-circle{
    border-radius: 10px;
  }
  .btn-buy, .btn-yellow {
    color: #ffffff;
    background-color: #ffb300;
    font-size:16px;
  }
  .btn-yellow {
    color: #ffffff;
    background-color: #ffb300;
  }

  .valueTittle {
    font-size: 26px;
    color: #ff6f00;
    font-weight: bold;
  }
  .valueProduct {
    font-size: 30px;
    color: #ff6f00;
    font-weight: bold;
  }
  .valueBeforeTittle {
    font-size: 20px;
  }
  .valueBefore {
    font-size: 21px;
    text-decoration:line-through;
  }
  .cont-product {
    border-radius: 10px;
  }
  .nameProduct {
    font-size: 24px;
    color: #343434;
  }
  .color-blue {
    color:darkblue;
  }

  .table-buy  {
    border-radius: 15px !important;
    border: 2px solid #c6c6c6 !important;
  }

  .table-buy tbody {
    columns: #000000;;
  }
  .table-buy thead {
    color:#ff6400;
    font-weight: bold;
    font-size:16px
  }

  .table-buy .total {
    color: #9a9a9a;
    font-size: 16px;
    font-weight: bold;
  }

  .table-buy .totalPrice {
    color: #ff6f00;
    font-size: 24px;
    font-weight: bold;
  }

  .infoBuy {
    line-height: 1.2;
  }

  .btn-yellow {
    background-color: #ffb300;
    color: #ffffff;
  }

  .btn-yellow:hover {
    background-color: #ffb300;
    color: #ffffff;
    font-weight: bold;
  }
  .btn-blue-skin{
    background-color:#ff6400;
    color: #ffffff;
  }

  .btn-blue-skin:hover{
    background-color:#ffb300;
    color: #ffffff;
    font-weight: bold;
  }

  .btn-blue-quota {
    background-color:#ff6400;
    color: #ffffff;
    font-weight: bold;
    width: 400px;
  }

  .btn-blue-quota:hover{
    background-color: #ff7300;
    color: #ffffff;
    font-weight: bolder;
  }

  .modal-content{
    position: relative;
    background-color: #ffffff;
    border: 1px solid #999999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px; /*<!-- HERE I AM!---*/
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    outline: none;
    color: #000000 !important;
}

.modalProduct input {
  width: 200px;
  border-radius: 10px;
}
  .color_orange {
    color: #ff6f00;
  }

}

.btnStep2, .textmessage, .divPhone,.divEmail {
  display: none;
}
.btnStep {
  color: #000 !important;
}
#showQuota {
  display: none;
  text-align: center;
  font-size: 20px;
}
#showQuota .quota {
  color: #ffb300;
  font-size: 24px;
  text-align: center;
  font-weight: bolder;

}

.msgWritteIdentification, .msgWritteCellphone, .msgWritteEmail, .msgWritteCincorrect {
  display: none;
  color: red;
  font-size: 11px;
}
.errorQuota {
  display: none;
  color: red;
  font-size: 15px;
}
.error_list {
  color: red;
}
.contProduct {
  text-align: center;
  background-color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding-bottom: 5px;
  -webkit-box-shadow: 10px 8px 8px -3px #bababa;
  box-shadow: 10px 8px 8px -3px #bababa;

}

.cont-product .image_container {
  margin-top: 15px;

  margin-bottom: 15px;
}
.contProduct img {

}
.total_price {
  text-align: right;
  line-height: 1.1;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.value_before {
  color: #B4AEAE;
}

.price_before {
  color: #B4AEAE;
  text-decoration:line-through;
}
#selQuota {
  width: 50px;
}

.contProduct .discount {
  color: #F32020;
  background-color: #F4F428;
  padding-top: 14px !important;
  font-weight: bold;
  text-align: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  line-height: 1.1;
}
.contProduct .product-name {
  color: #b18431;
  font-size: 16px;
  padding-top: 15px;
  font-weight: bold;
  height: 70px;

}
.total_product {
  color: #000000;
  font-weight: bold;
}
.dcto {
  font-size: 10px;
}

.btn-products {
  font-size: 12px;
  width: 100%;
  line-height: 1.1;
  padding-top: 8px;
  padding-bottom: 8px;
}
.btn-products.bvp {
  background-color:#5c5c59;
  color: #ffffff;

 }

 .btn-products.bac {
   color: #ffffff;
   background-color: #ffb300;
}
.divBtnProducts {
  padding-left:  5px !important;
  padding-right:  5px !important;

}

.modalProduct input {
  text-align: center;
}



 *,
:after,
:before {
  box-sizing: border-box
}

.clearfix:after,
.clearfix:before {
  content: '';
  display: table
}

.clearfix:after {
  clear: both;
  display: block
}
ul{
	list-style:none;
	margin: 0;
	padding: 0;
}
a, a:hover, a.active, a:active, a:visited, a:focus{
	color:#fefefe;
	text-decoration:none;
}
.content{
	margin: 50px 100px 0px 100px;
}

.exo-menu{
	width: 100%;
	float: left;
	list-style: none;
	position:relative;
	background: #23364B;
}
.exo-menu > li {	display: inline-block;float:left;}
.exo-menu > li > a{
  font-size: 12px;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	border-right: 0.5px #fff dotted;
	-webkit-transition: color 0.2s linear, background 0.2s linear;
	-moz-transition: color 0.2s linear, background 0.2s linear;
	-o-transition: color 0.2s linear, background 0.2s linear;
	transition: color 0.2s linear, background 0.2s linear;
}
.exo-menu > li > a.active,
.exo-menu > li > a:hover,
li.drop-down ul > li > a:hover{
	background:#009FE1;
	color:#fff;
}
.exo-menu i {
  float: left;
  font-size: 12px;
  margin-right: 4px;
  line-height: 20px !important;
}
li.drop-down,
.flyout-right,
.flyout-left{position:relative;}
li.drop-down:before {
  content: "\f103";
  color: #fff;
  font-family: FontAwesome;
  font-style: normal;
  display: inline;
  position: absolute;
  right: 6px;
  top: 20px;
  font-size: 14px;
}
li.drop-down>ul{
	left: 0px;
	min-width: 230px;

}
.drop-down-ul{display:none;}
.flyout-right>ul,
.flyout-left>ul{
  top: 0;
  min-width: 230px;
  display: none;
  border-left: 1px solid #365670;
  }

li.drop-down>ul>li>a,
.flyout-right ul>li>a ,
.flyout-left ul>li>a {
	color: #fff;
	display: block;
	padding: 20px 22px;
	text-decoration: none;
	background-color: #365670;
	border-bottom: 1px dotted #547787;
	-webkit-transition: color 0.2s linear, background 0.2s linear;
	-moz-transition: color 0.2s linear, background 0.2s linear;
	-o-transition: color 0.2s linear, background 0.2s linear;
	transition: color 0.2s linear, background 0.2s linear;
}
.flyout-right ul>li>a ,
.flyout-left ul>li>a {
	border-bottom: 1px dotted #B8C7BC;
}


/*Flyout Mega*/
.flyout-mega-wrap {
	top: 0;
	right: 0;
	left: 100%;
	width: 100%;
	display:none;
	height: 100%;
	padding: 15px;
	min-width: 742px;

}
h4.row.mega-title {
  color:#eee;
  margin-top: 0px;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  min-height: 62px;
  border-right: 0.5px dashed #fff;
  text-align: center;
  display:block;
 }
.flyout-mega ul > li > a {
  font-size: 90%;
  line-height: 25px;
  color: #fff;
  font-family: inherit;
}
.flyout-mega ul > li > a:hover,
.flyout-mega ul > li > a:active,
.flyout-mega ul > li > a:focus{
  text-decoration: none;
  background-color: transparent !important;
  color: #ccc !important
}
/*mega menu*/

.mega-menu {
  left: 0;
  right: 0;
  padding: 15px;
  display:none;
  padding-top: 0;
  min-height: 100%;
  z-index: 100;

}
h4.row.mega-title {
  color: #eee;
  margin-top: 0px;
  font-size: 14px;
  padding-left: 15px;
  padding-bottom: 13px;
  text-transform: uppercase;
  border-bottom: 1px solid #547787;
  padding-top: 15px;
  background-color: #474747;
  }
 .mega-menu ul li a {
  line-height: 15px;
  font-size: 90%;
  display: block;
  color: #474747;
}
ul.stander li a {
    padding: 3px 0px;
}

ul.description li {
    padding-top: 12px;
    line-height: 8px;
}

ul.description li span {
    color: #ccc;
    font-size: 85%;
}
a.view-more{
  border-radius: 1px;
  margin-top:15px;
  background-color: #009FE1;
  padding: 2px 10px !important;
  line-height: 21px !important;
  display: inline-block !important;
}
a.view-more:hover{
	color:#fff;
	background:#0DADEF;
}
ul.icon-des li a i {
    color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    background-color: #009FE1;
    line-height: 35px !important;
}

ul.icon-des li {
    width: 100%;
    display: table;
    margin-bottom: 11px;
}
/*Blog DropDown*/
.Blog{
	left:0;
	display:none;
	color:#fefefe;
	padding-top:15px;
	background:#547787;
	padding-bottom:15px;
}
.Blog .blog-title{
	color:#fff;
	font-size:15px;
	text-transform:uppercase;

}
.Blog .blog-des{
	color:#ccc;
	font-size:90%;
	margin-top:15px;
}
.Blog a.view-more{
	margin-top:0px;
}
/*Images*/
.Images{
	left:0;
   width:100%;
	 display:none;
	color:#fefefe;
	padding-top:15px;
	background:#547787;
	padding-bottom:15px;
}
.Images h4 {
  font-size: 15px;
  margin-top: 0px;
  text-transform: uppercase;
}
/*common*/
.flyout-right ul>li>a ,
.flyout-left ul>li>a,
.flyout-mega-wrap,
.mega-menu{

	background-color: #fff;
  /*background-color: #547787;*/
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
}

/*hover*/
.Blog:hover,
.Images:hover,
.mega-menu:hover,
.drop-down-ul:hover,
li.flyout-left>ul:hover,
li.flyout-right>ul:hover,
.flyout-mega-wrap:hover,
li.flyout-left a:hover +ul,
li.flyout-right a:hover +ul,
.blog-drop-down >a:hover+.Blog,
li.drop-down>a:hover +.drop-down-ul,
.images-drop-down>a:hover +.Images,
.mega-drop-down a:hover+.mega-menu,
li.flyout-mega>a:hover +.flyout-mega-wrap{
	display:block;
}
/*responsive*/
 @media (min-width:767px){

	.exo-menu > li > a{
	display:block;
	padding: 15px 10px;
 }
.mega-menu, .flyout-mega-wrap, .Images, .Blog,.flyout-right>ul,
.flyout-left>ul, li.drop-down>ul{
		position:absolute;
}
 .flyout-right>ul{
	left: 100%;
	}
	.flyout-left>ul{
	right: 100%;
}
.toggle-menu{
  display:none !important;
}
 }
@media (max-width:767px){
  .exo-menu{
    display:none !important;  
  }

  .menu-select-resp{
    display:block !important;  
  }
}
a.toggle-menu{
    position: absolute;
    right: 0px;
    padding: 20px;
    font-size: 27px;
    background-color: #ccc;
    color: #23364B;
    top: 0px;
}

nav.row{
  flex: none !important;
}

.exo-sub-menu{
    color: #ffffff !important;
}

.nav-pills .nav-link.active{
    background-color: #474747;
    color: white;
}
.nav-pills .nav-link:not(.active) {
    background-color: #fff;
    color: black;
}
.carousel-indicators {
    z-index: 10 !important;
}


.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}


.menu-select-resp{
  .main-opt{
    font-size: 18px;
  }
}

