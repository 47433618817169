// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

@import "~jqwidgets-scripts/jqwidgets/styles/jqx.base.css";

//Vuefity
@import '~vuetify/dist/vuetify.min.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Custom
@import 'custom';



